<template>
  <div id="tags-view-container" class="tags-view-container">
    <el-scrollbar>
      <div class="scrollbar-flex-content">

        <router-link
          v-for="tag in visitedViews"
          ref="tag"
          :key="tag.path"
          :class="isActive(tag)?'active':''"
          :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
          tag="div"
          class="tag-item "
          @contextmenu.prevent="openMenu(tag,$event)"
        >
          <!--          @click="!isAffix(tag)?closeSelectedTag(tag):''"-->
          <span>{{ tag.name }}</span>
          <quick-icon icon="el-icon-Close" v-if="!isAffix(tag)"
                      @click.prevent.stop="closeSelectedTag(tag)"></quick-icon>
        </router-link>
      </div>
    </el-scrollbar>
    <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
      <li @click="refreshSelectedTag(selectedTag)">刷新</li>
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">关闭</li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags(selectedTag)">全部关闭</li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>

.scrollbar-flex-content {
  display: flex;
  padding: 5px 20px 6px;
}

.tag-item:last-child {
  margin-right: 0
}

.tag-item:hover:not(.active) {
  background-color: #eee
}

.tag-item:hover .el-icon-close {
  width: 14px;
  margin-left: 5px
}

.tag-item:hover i {
  width: auto;
}

.tag-item.active span {
  color: $g-color-primary;
  font-weight: 700;
  user-select: none
}

.tag-item.active i {
  width: auto;
  margin-left: 5px
}

.tag-item.active:before {
  background-color: $g-color-primary;
}

.tag-item {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  background-color: #fff;
  font-size: 12px;
  margin-right: 10px;
  color: #909399;
  cursor: pointer;
  text-decoration-line: none;

  i {
    font-size: 14px;
    width: auto;
    overflow: hidden;
    transition: all .3s
  }

  i:hover {
    color: #fff;
    background-color: $g-color-primary;
  }
}

.tags-view-container {
  //margin-top: 50px;
  //position: absolute;
  //height: 50px;
  width: 100%;
  background: #f0f2f5;
  //border-bottom: 1px solid #d8dce5;
  //box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);
  .tags-view-wrapper {
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;

      &:first-of-type {
        margin-left: 15px;
      }

      &:last-of-type {
        margin-right: 15px;
      }

      &.active {
        background-color: #42b983;
        color: #fff;
        border-color: #42b983;

        &::before {
          content: '';
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 2px;
        }
      }
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }
}
</style>


<script>
import path from 'path'

export default {

  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      editableTabsValue: '',
      selectedTag: {},
      affixTags: []
    }
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
    routes() {
      return this.$store.state.menu.routes
    },
    oneArrMenus() {
      return this.$store.state.menu.oneArrMenus
    },
  },
  watch: {
    $route() {
      this.addTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    Quick.$on("load_menu_finish", () => {
      this.initTags()
      this.addTags()
    })
    Quick.$on("tag_view_title", (fullPath, data) => {
      console.log('---tag_view_title-----', fullPath, data)
    })
  },
  methods: {
    isActive(route) {
      return route.path === this.$route.path
    },
    isAffix(tag) {
      if (tag.path === '/dashboard' && this.visitedViews.length === 1) {
        return true;
      }
      return tag.meta && tag.meta.affix
    },
    filterAffixTags(routes, basePath = '/') {
      let tags = []
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path)
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: {...route.meta}
          })
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path)
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags]
          }
        }
      })
      return tags
    },
    initTags() {
      const affixTags = this.affixTags = this.filterAffixTags(this.routes)
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch('tagsView/addVisitedView', tag)
        }
      }
    },
    getRouteName() {

      // spm path 全部匹配
      if (this.$route.query.spm && this.oneArrMenus[this.$route.query.spm]) {
        if (this.$route.path === this.oneArrMenus[this.$route.query.spm].path) {
          return this.oneArrMenus[this.$route.query.spm]
        }
      }

      // 匹配菜单 path
      for (let key in this.oneArrMenus) {
        if (this.oneArrMenus[key].path === this.$route.path) {
          return this.oneArrMenus[key]
        }
      }
      // 匹配 节点 path
      if (this.$route.path.slice(1) in this.$store.state.menu.nodes) {
        return {
          name: this.$store.state.menu.nodes[this.$route.path],
          title: this.$store.state.menu.nodes[this.$route.path],
          path: this.$route.path,
        }
      }
      let url = this.$route.path.slice(1).split("/");
      url = url.length >= 4 &&  url.splice(0,4).join("/")
      if(url in this.$store.state.menu.nodes){
        let data = {
          name: this.$store.state.menu.nodes[url],
          title: this.$store.state.menu.nodes[url],
          path: this.$route.path,
        }
        // 节点跟随菜单缓存配置
        if (this.$route.query.spm && this.oneArrMenus[this.$route.query.spm]) {
          data.view_cache =  this.oneArrMenus[this.$route.query.spm]['view_cache']
        }
        return data
      }

      // 匹配 spm
      if (this.$route.query.spm && this.oneArrMenus[this.$route.query.spm]) {
        return this.oneArrMenus[this.$route.query.spm]
      }

    },
    addTags() {
      const {name} = this.$route
      if (name && name !== 'reload') {
        this.$store.dispatch('tagsView/addView', this.getRouteObject())
      }
      return false
    },
    getRouteObject() {
      let menuInfo = this.getRouteName();
      return JSON.parse(JSON.stringify({
        fullPath: this.$route.fullPath,
        meta: this.$route.meta,
        query: this.$route.query,
        params: this.$route.params,
        path: this.$route.path,
        noCache: !(menuInfo?.view_cache || false),
        href: this.$route.href,
        name: menuInfo?.name || this.$route.name,
      }))
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            // this.$refs.scrollPane.moveToTarget(tag)
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('tagsView/updateVisitedView', this.getRouteObject())
            }
            break
          }
        }
      })
    },
    refreshSelectedTag(view) {
      this.$store.dispatch('tagsView/delCachedView', view).then(() => {
        const {fullPath} = view
        let url = '/redirect' + fullPath;
        this.$nextTick(() => {
          this.$router.replace(url)
        })
      })
    },
    closeSelectedTag(view) {
      this.$store.dispatch('tagsView/delView', view).then(({visitedViews}) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view)
        }
        // setTimeout(() => {
        //   this.$store.dispatch('tagsView/delCachedView', view)
        //   console.log('--------dfd',this.$store.state.tagsView)
        // },2000)
      })
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag.fullPath)
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
        // this.$store.dispatch('tagsView/delOthersCachedViews', this.selectedTag)
      })
    },
    closeAllTags(view) {
      this.$store.dispatch('tagsView/delAllViews').then(({visitedViews}) => {
        if (this.affixTags.some(tag => tag.path === view.path)) {
          return
        }
        this.toLastView(visitedViews, view)
        // this.$store.dispatch('tagsView/delAllCachedViews', view)
      })
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({path: '/redirect' + view.fullPath})
        } else {
          this.$router.push('/')
        }
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      let _top = -10;
      if(this.$store.state.settings.menuMode == 'head'){
        _top = 55;
      }
      this.top = e.clientY - _top
      this.visible = true
      this.selectedTag = tag
    },
    closeMenu() {
      this.visible = false
    },
    handleScroll() {
      this.closeMenu()
    }
  }
}
</script>
