import defaults from 'lodash/defaults'

export default {

  data(){
    return {
      tableClass:'table'
    }
  },
  computed:{
    filterData() {
      return this.$store.getters['filters/getFilter'](this.tableClass)
    }
  },
  mounted() {
    // this.tableClass = 'table_'+Math.random()
    this.tableClass = 'table'
    this.$store.commit('filters/clearFilters')
  },
  methods: {

    updateFilterAll(value) {
      this.filterData = value
    },
    /**
     * Update the given query string values.
     */
    updateFilter(value) {
      console.log('-------updateFilter',value)
      let filter = defaults(value, this.filterData)
      this.$store.commit('filters/storeFilters',[
        {
          class:this.tableClass,
          currentValue:filter,
        }
      ])
    },
    updateQueryString(value) {
      this.$router.push({ query: defaults(value, this.$route.query) })
    }
  }
}
