<template>
    <router-link :to="to" class="title" :class="{'is-link': $store.state.settings.enableDashboard}" :title="title">
        <img v-if="showLogo" :src="logo" class="logo">
        <span v-if="showTitle">{{ title }}</span>
    </router-link>
</template>

<script setup>
  import { computed , ref } from 'vue';
  import { useStore } from 'vuex';
import imgLogo from '@/assets/logo.png'
const store = useStore()

defineProps({
    showLogo: {
        type: Boolean,
        default: true
    },
    showTitle: {
        type: Boolean,
        default: true
    }
})

const title = Quick?.config?.appName || 'QuickAdmin'

const logo = ref(Quick?.config?.appLogo || imgLogo)

const to = computed(() => {
    let rtn = {}
    if (store.state.settings.enableDashboard) {
        if(Quick?.config?.dashboard){
          rtn.path = Quick.config.dashboard
        }else{
          rtn.name = 'dashboard'
        }


    }
    return rtn
})
</script>

<style lang="scss" scoped>
.title {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: inherit;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $g-sidebar-logo-height;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    &.is-link {
        cursor: pointer;
    }
    .logo {
        width: 30px;
        height: 30px;
        & + span {
            margin-left: 10px;
        }
    }
    span {
        display: block;
        font-weight: bold;
        color: #fff;
        @include text-overflow;
    }
}
</style>
