<template>
  <div class="export-btn-box">
    <el-button @click="handleOpen">{{btnName}}</el-button>
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      width="50%"
      :before-close="handleClose"
    >

      <el-form>
        <div class="modal-body">
          <el-checkbox class="all-choose" :indeterminate="isIndeterminate" v-model="checkAll"
                       @change="exportCheckAll">{{__('全选')}}
          </el-checkbox>
          <el-checkbox-group class="choose-list" v-model="checkedFields" @change="exportCheck">
            <el-checkbox class="export-checkbox" :style="{width:itemWidth+'px'}" v-for="item in fieldList" :key="item.key"
                         :label="item.key">{{item.value}}
            </el-checkbox>
          </el-checkbox-group>
        </div>

      </el-form>


      <template #footer>
      <span class="dialog-footer">
        <div v-if="pageTypes.length > 0">
         <el-radio-group v-model="pageType" class="ml-4">
            <el-radio label="select" v-if="pageTypes.includes('select')">{{__('已选')}}
              {{actionData._keyValues_.length}}{{__('条数据')}}</el-radio>
            <el-radio label="current" v-if="pageTypes.includes('current')">{{__('当前页')}}</el-radio>
            <el-radio label="all" v-if="pageTypes.includes('all')">{{__('全部')}}</el-radio>
          </el-radio-group>
        </div>
        <div>
           <el-button @click="dialogVisible = false">{{__('Cancel')}}</el-button>
        <el-button type="primary" :loading="loading" @click="handleExport">
          {{btnName}}
        </el-button>
        </div>
      </span>
      </template>
    </el-dialog>
    <div class='image-dialog' :class="{'start-animation': isStart}" ref="image_dialog"></div>
  </div>
</template>
<script>
import {useAction} from "../../utils/handleAction";

export default {
  name: 'export-btn',
  props: {
    fieldList:{
      type:Array,
      default:() => []
    },
    pageTypes:{
      type:Array,
      default:() => []
    },
    exportUrl:{
      type:String,
      default:''
    },
    title:{
      type:String,
      default:'导出'
    },
    btnName:{
      type:String,
      default:'导出'
    },
    itemWidth:{
      type:Number,
      default:130
    },
    actionData:{
      type: [Array,Object],
      default: () => []
    },
    actionParams:{
      type: [Array,Object],
      default: () => []
    },
  },
  computed: {

  },
  data(){
    return {
      dialogVisible:false,
      checkAll:false,
      checkedFields:[],
      loading:false,
      pageType:'all',
      isStart:false,
    }
  },
  mounted() {

    this.exportCheckAll(true);

    setTimeout(function() {
      // let dwonload_params = localStorage.getItem('_SYSTEM_DOWNLOAD_PARAMS');
      // dwonload_params = JSON.parse(dwonload_params);

      let left = 1009;
      let top = 1;
      var style = document.styleSheets[17];
      style.insertRule(
        "@keyframes myfirst{25% { border-radius: 100%; width: 17px; height: 17px; top: calc(50% - 8.5px / 2); " +
        "left: calc(50% - 8.5px / 2); background-image: url('/assets/images/download-2.png'); } 100% { width: 17px; height: 17px; " +
        "top: "+top+"px; left: "+left+"px; background-image: url('/assets/images/download-2.png'); visibility: hidden;}");//写入样式
    }, 500);
  },
  methods:{
    exportCheckAll(val) {
      if (val) {
        let field_list = this.fieldList;
        let array = [];
        field_list.forEach((item, index) => {
          array.push(item['key']);
        });
        this.checkedFields = array;
        this.checkAll = true;
      } else {
        this.checkedFields = [];
      }
      this.isIndeterminate = false;
    },
    exportCheck(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldList.length;
    },
    handleOpen(){
      this.dialogVisible = true;

    },
    handleClose(){
      this.dialogVisible = false;
    },
    handleExport(){
      let queryParam = {}
      let filter = this.$store.getters['filters/getFilter']('table');
      if(this.actionData.filterData){
        filter = this.actionData.filterData;

      }


      queryParam = Object.assign({},queryParam,filter,this.actionData,this.actionParams)
      queryParam._fields = this.checkedFields;
      queryParam._pageType = this.pageType;
      queryParam._flag = 'EXPORT';
      // if(this.actionData && this.actionData._keyValues_){
      //   queryParam._keyValues_ = this.actionData._keyValues_;
      // }
      if(this.loading){
        return
      }
      this.loading = true
      Quick.request().post(
        this.exportUrl,
        queryParam,
      ).then((data) => {


        this.loading = false


        if(data.action){
          useAction({
            callback:(type,aData) => {
              // console.log('-----------callback',type,aData)
              if(type === 'queue'){
                this.isStart = true
                this.dialogVisible = false;
                setTimeout(() => {
                  this.isStart = false
                }, 3000);
              }


            }
          }).action(data.action)
        }

      }).catch(() => {
        this.loading = false
        this.dialogVisible = false;
      })
    }
  }
}
</script>
<style lang="scss">
  .dialog-footer{
    display: flex;
    justify-content: space-between;
  }
  .export-btn-box{
    display: inline-block;
  }

  .export-btn-box .image-dialog {
    width: 294px;
    height: 212px;
    top: calc(50% - 212px / 2);
    left: calc(50% - 294px / 2);
    background-image: url('/assets/images/export-loading.gif');
    position: fixed;
    z-index: 99;
    background-size: cover;
    visibility: hidden;
  }

  .export-btn-box .start-animation {
    visibility: visible;
    animation:myfirst 1.5s ease;
    animation-delay:1.5s;
    animation-fill-mode:forwards;
  }

  //@keyframes myfirst
  // {
  //     0%   {}
  //     25%  {}
  //     50%  {border-radius: 25%;}
  //     75%  {
  //         border-radius: 100%;
  //         width: 17px;
  //         height: 17px;
  //         top: calc(50% - 8.5px / 2);
  //         left: calc(50% - 8.5px / 2);
  //         background-image: url('/assets/images/download-2.png');
  //     }
  //     99.9% {
  //         width: 17px;
  //         height: 17px;
  //         top: 22.875px;
  //         left: 1054px;
  //         background-image: url('/assets/images/download-2.png');
  //     }
  //     100% {
  //         visibility: hidden;
  //     }
  // }
</style>
